// import React from 'react'
// import './RelatedProducts.css'
// import data_product from '../Assets/data'
// import Item from '../Item/Item'
// const RelatedProducts = () => {
//   return (
//     <div className='relatedproducts'>
//       <h1>Related Products</h1>
//       <hr />
//       <div className="relatedproducts-item">
//         {data_product.map((item,i)=>{
//             return <Item key={i} id={item.id} name={item.name} image={item.image} new_price={item.new_price} old_price={item.old_price}/>
//         })}
//       </div>
//     </div>
//   )
// }

// export default RelatedProducts

import React from 'react';
import './RelatedProducts.css';
import Item from '../Item/Item';

const RelatedProducts = ({ relatedProducts }) => {
  return (
    <div className='relatedproducts'>
      <h1>Related Products</h1>
      <hr />
      <div className="relatedproducts-item">
        {relatedProducts.map((item, i) => (
          <Item
            key={i}
            id={item.id}
            name={item.product_name}
            image={item.main_image}
            new_price={item.offer_price}
            old_price={item.mrp}
            rating={item.rating}
          />
        ))}
      </div>
    </div>
  );
};

export default RelatedProducts;




