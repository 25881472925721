import React, { useEffect, useState } from 'react'
import './NewCollections.css'
import Item from '../Item/Item'
import axios from 'axios'

const NewCollections = () => {
  const [NewCollectionsData, setNewCollectionsData] = useState([]);

  useEffect(() => {
    async function getData() {
      try {
        const response = await axios.get("https://kraafty-backend.onrender.com/api/get-new-arrival-product/");
        console.log("data",response.data.data);
        setNewCollectionsData(response.data.data);
      } catch(error){
        console.log("error",error);
      }
    }
    getData();
  },[]);


  return (
    <div className='new-collections'>
      <h1>NEW COLLECTIONS</h1>
      <hr />
      <div className="collections">
        {NewCollectionsData
        .filter(item => item.new_arrival && item.is_active)
        .map((item) => (
          <Item
            key={item.id}
            id={item.id}
            name={item.product_name}
            image={item.main_image}
            new_price={item.offer_price}
            old_price={item.mrp}
            rating={item.rating}
            />
        ))
        }
      </div>
    </div>
  )
}

export default NewCollections
