import React, { useRef, useState, useEffect } from 'react';
import './Hero.css';
import Ad from '../Assets/Fashion_ad1.mp4';
import Navbar from '../Navbar/Navbar';

const Hero = () => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true); // Default to muted

  // Set the video volume to 40% when unmuted
  const handleMuteUnmute = () => {
    if (isMuted) {
      videoRef.current.muted = false;
      videoRef.current.volume = 0.4; // Set volume to 40% when unmuted
    } else {
      videoRef.current.muted = true;
    }
    setIsMuted(!isMuted);
  };

  return (
    <div className="hero-container">
      {/* <Navbar /> */}
      <video
        className="hero-video"
        ref={videoRef}
        autoPlay
        loop
        muted={isMuted} // Start muted
        src={Ad}
      />
      <button className="control-button" onClick={handleMuteUnmute}>
        {isMuted ? 'Unmute' : 'Mute'}
      </button>
    </div>
  );
};

export default Hero;


// const Hero = () => {
//   return (
//     <div className='hero'>
//       <div className="hero-left">
//         <h2>NEW ARRIVALS ONLY</h2>
//         <div>
//             <div className="hero-hand-icon">
//                 <p>new</p>
//                 <img src={hand_icon} alt="" />
//             </div>
//             <p>collections</p>
//             <p>for everyone</p>
//         </div>
//         <div className="hero-latest-btn">
//             <div>Latest Collection</div>
//             <img src={arrow_icon} alt="" />
//         </div>
//       </div>
//       <div className="hero-right">
//         <img src={hero_image} alt="" />
//       </div>
//     </div>
//   )
// }

// export default Hero
