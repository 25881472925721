import React from "react";
import {
  Form,
  Input,
  InputNumber,
  Switch,
  Button,
  Select,
  Row,
  Col,
  Typography,
} from "antd";
import "./CSS/AddItems.css";

const { Title } = Typography;

const AddItems = () => {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    console.log("Form values:", values);
    await localStorage.setItem("items", JSON.stringify(values));
  };

  const categoryItems = ["Men", "Women", "Kids"];

  return (
    <div className="form-container">
      <Title level={3} style={{ textAlign: "center", marginBottom: "20px" }}>
        Add New Item
      </Title>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          quantity: 10,
          is_active: true,
          new_arrival: true,
          top_selling: true,
        }}
        className="items_form"
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Category"
              name="category"
              rules={[{ required: true, message: "Please select a category!" }]}
            >
              <Select placeholder="Select Category">
                {categoryItems?.map((label, key) => (
                  <Select.Option key={key} value={key}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="product_name"
              label="Product Name"
              rules={[
                { required: true, message: "Please enter the product name!" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="product_code" label="Product Code">
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="size" label="Size">
              <Select>
                <Select.Option>Small</Select.Option>
                <Select.Option>Medium</Select.Option>
                <Select.Option>Large</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="is_active"
              label="Is Active"
              valuePropName="checked"
            >
              <Switch checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="new_arrival"
              label="New Arrival"
              valuePropName="checked"
            >
              <Switch checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="top_selling"
              label="Top Selling"
              valuePropName="checked"
            >
              <Switch checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>
          </Col>

          <Col span={18}>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                { required: true, message: "Please enter the description!" },
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="quantity" label="Quantity">
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="rating" label="Rating">
              <Select>
                <Select.Option value="1">1</Select.Option>
                <Select.Option value="2">2</Select.Option>
                <Select.Option value="3">3</Select.Option>
                <Select.Option value="4">4</Select.Option>
                <Select.Option value="5">5</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="offer_price"
              label="Offer Price"
              rules={[
                { required: true, message: "Please enter the offer price!" },
              ]}
            >
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="mrp"
              label="MRP"
              rules={[{ required: true, message: "Please enter the MRP!" }]}
            >
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="main_image" label="Main Image URL">
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="second_media" label="Second Media URL">
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="third_media" label="Third Media URL">
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="fourth_media" label="Fourth Media URL">
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="fifth_media" label="Fifth Media URL">
              <Input />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="remarks" label="Remarks">
              <Input.TextArea rows={3} />
            </Form.Item>
          </Col>

          <Col span={24} style={{ textAlign: "center" }}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddItems;
