
import React from "react";
import "./AboutUs.css";
import Image from "../Assets/Image_cart.jpg";

const AboutUs = () => {
  return (
    // <section className="bg-gradient-to-b from-purple-500 to-pink-400 py-16 px-4 sm:px-6 lg:px-8 w-full md:w-4/5 mx-auto">
    <div className="about">
      <h1 className="about-title">ABOUT US</h1>
      <hr/>
      <div className="about-container">
        <div className="about-content">
          <p className="about-text">
            Founded in Jaipur in 2018, <strong>Kraafty</strong> brings India’s rich craftsmanship to the world with eco-friendly, handcrafted décor and textiles. Each piece, crafted from natural materials, supports fair wages for artisans and promotes sustainability.
          </p>
          <p className="about-text">
          More than a brand, Kraafty connects customers with <strong>authentic Indian heritage, blending tradition with ethical, high-quality craftsmanship.</strong>
          </p>
          {/* <p className="about-text">
            We hope you enjoy our products as much as we enjoy offering them to
            you. If you have any questions or comments, please don't hesitate to
            contact us.
          </p> */}
          <p className="about-signature">
            Sincerely,
            <br />
            <strong>Kuldeep Khandelwal</strong>
            <br />
            Founder, Kraafty
          </p>
        </div>
        <div className="about-image">
          <img src={Image} alt="About ShopEase" />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
