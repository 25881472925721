// import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
// import UpdateDisabledOutlinedIcon from "@mui/icons-material/UpdateDisabledOutlined";
// import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import './DeliverySection.css';
import { SafetyOutlined, StopOutlined, TruckOutlined } from "@ant-design/icons";
export default function DeliverySection() {
  return (
    <div className="deliverySections">
      <div className="items">
        {/* <LocalShippingOutlinedIcon /> */}
        <TruckOutlined style={{fontSize:"x-large"}}/>
        <p style={{ color: "#007185" }}>Free delivery</p>
      </div>
      <div className="items"> 
        {/* <UpdateDisabledOutlinedIcon /> */}
        <StopOutlined style={{fontSize:"x-large"}}/>
        <p style={{ color: "#007185" }}>Non-Returnable</p>
      </div>
      <div className="items">
        {/* <HttpsOutlinedIcon /> */}
        <SafetyOutlined style={{fontSize:"x-large"}}/>
        <p style={{ color: "#007185" }}>Secure Transactions</p>
      </div>
    </div>
  );
}
