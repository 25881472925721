// import React, { useState, useEffect } from 'react';

// const Notification = ({ message, isVisible, onClose }) => {
//   useEffect(() => {
//     if (isVisible) {
//       const timer = setTimeout(() => {
//         onClose();
//       }, 3000); // Auto-close after 3 seconds
//       return () => clearTimeout(timer);
//     }
//   }, [isVisible, onClose]);

//   return (
//     <>
//       {isVisible && (
//         <div className="fixed bottom-10 right-10 p-4 bg-green-500 text-white rounded-lg shadow-lg animate-slide-up">
//           {message}
//         </div>
//       )}
//     </>
//   );
// };

// export default Notification;

import React, { useState, useEffect } from 'react';

const Notification = ({ message, isVisible, onClose }) => {
  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        onClose();
      }, 3000); // Auto-close after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [isVisible, onClose]);

  return (
    <>
      {isVisible && (
        <div className="fixed inset-0 flex items-center justify-center">
          <div className="p-6 w-96 bg-green-500 text-white rounded-lg shadow-lg animate-slide-up text-center">
            {message}
          </div>
        </div>
      )}
    </>
  );
};

export default Notification;

