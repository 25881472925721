// import React, { useState } from "react";
// import review1 from "./ScreenShots/review1.jpeg";
// import review2 from "./ScreenShots/review2.jpeg";
// import review3 from "./ScreenShots/review3.jpeg";
// import review4 from "./ScreenShots/review4.jpeg";
// import review5 from "./ScreenShots/review5.jpeg";
// import review6 from "./ScreenShots/review6.jpeg";
// import review7 from "./ScreenShots/review7.jpeg";
// import review8 from "./ScreenShots/review8.jpeg";
// import review9 from "./ScreenShots/review9.jpeg";
// import review10 from "./ScreenShots/review10.jpeg";
// import review11 from "./ScreenShots/review11.jpeg";
// import review12 from "./ScreenShots/review12.jpeg";
// import review13 from "./ScreenShots/review13.jpeg";
// import review14 from "./ScreenShots/review14.jpeg";
// import review15 from "./ScreenShots/review15.jpeg";

// const CustomerReviews = () => {
//   // Array of imported images
//   const reviewImages = [
//     review1, review2, review3, review4, review5,
//     review6, review7, review8, review9, review10,
//     review11, review12, review13, review14, review15
//   ];

//   // State to track how many reviews are currently visible
//   const [visibleReviews, setVisibleReviews] = useState({
//     desktop: 10,
//     mobile: 6,
//   });
//   const [showMore, setShowMore] = useState(false);

//   // Function to handle the "See More" button click
//   const handleSeeMore = () => {
//     setShowMore(true);
//   };

//   // Determine how many reviews to show based on screen size
//   const reviewsToDisplay = () => {
//     const isMobile = window.innerWidth < 640;
//     return showMore ? reviewImages.length : isMobile ? visibleReviews.mobile : visibleReviews.desktop;
//   };

//   return (
//     <div className="p-6 w-4/5 mx-auto">
//       <h2 className="text-center text-3xl font-bold mb-6">CUSTOMER REVIEWS</h2>
//       <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
//         {reviewImages.slice(0, reviewsToDisplay()).map((image, index) => (
//           <div
//             key={index}
//             className="relative overflow-hidden rounded-lg shadow-lg group hover:shadow-xl transform hover:scale-105 transition-all duration-300"
//           >
//             <img
//               src={image}
//               alt={`Customer review ${index + 1}`}
//               className="w-full h-auto object-contain"
//             />
//             {/* Optional hover overlay */}
//             <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
//               <p className="text-white text-sm font-semibold">View Full</p>
//             </div>
//           </div>
//         ))}
//       </div>
      
//       {/* "See More" button */}
//       {!showMore && (
//         <div className="flex justify-center mt-6">
//           <button
//             onClick={handleSeeMore}
//             className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300"
//           >
//             See More
//           </button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default CustomerReviews;

import React from "react";
import review1 from "./ScreenShots/review1.jpeg";
import review2 from "./ScreenShots/review2.jpeg";
import review3 from "./ScreenShots/review3.jpeg";
import review4 from "./ScreenShots/review4.jpeg";
import review5 from "./ScreenShots/review5.jpeg";
import review6 from "./ScreenShots/review6.jpeg";
import review7 from "./ScreenShots/review7.jpeg";
import review8 from "./ScreenShots/review8.jpeg";
import review9 from "./ScreenShots/review9.jpeg";
import review10 from "./ScreenShots/review10.jpeg";
import review11 from "./ScreenShots/review11.jpeg";
import review12 from "./ScreenShots/review12.jpeg";
import review13 from "./ScreenShots/review13.jpeg";
import review14 from "./ScreenShots/review14.jpeg";
import review15 from "./ScreenShots/review15.jpeg";
import './CustomerReviews.css';

const CustomerReviews = () => {
  // Array of imported images
  const reviewImages = [
    review1, review2, review3, review4, review5,
    review6, review7, review8, review9, review10,
    review11, review12, review13, review14, review15
  ];

  return (
    <div className="p-6 w-4/5 mx-auto" id="customer-reviews-main">
      <h2 className="text-center text-3xl font-bold mb-6" id="customer-reviews">CUSTOMER REVIEWS</h2>
      <hr/>
      {/* Scrollable container for reviews */}
      <div
        className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 overflow-y-auto"
        style={{ maxHeight: '400px',marginTop:"40px" }} // Limit height for scrolling
      >
        {reviewImages.map((image, index) => (
          <div
            key={index}
            className="relative overflow-hidden rounded-lg shadow-lg group hover:shadow-xl transform hover:scale-105 transition-all duration-300"
          >
            <img
              src={image}
              alt={`Customer review ${index + 1}`}
              className="w-full h-auto object-contain"
            />
            {/* Optional hover overlay */}
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <p className="text-white text-sm font-semibold">View Full</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomerReviews;