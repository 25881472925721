import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollingUp = () => {
  const { pathname } = useLocation(); 
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top of the page when path changes
  }, [pathname]); // Effect is triggered when pathname changes

  return null;
};

export default ScrollingUp;
