import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

const Rating = ({ rating, showLabel, className, ...rest }) => (
  <div className={classNames("flex items-center", className)} {...rest}>
    {[...Array(5)].map((_, i) => {
      const index = i + 1;
      return (
        <FontAwesomeIcon
          key={i}
          icon={faStar}
          className={classNames(
            index <= rating ? "text-yellow-500" : "text-gray-300"
          )}
        />
      );
    })}
    {showLabel && <span className="ml-2 text-sm font-medium">{rating}</span>}
  </div>
);

Rating.propTypes = {
  rating: PropTypes.number.isRequired,
  showLabel: PropTypes.bool,
  className: PropTypes.string,
};

export default Rating;
