import {
  CustomerServiceOutlined,
  RightOutlined,
  SafetyCertificateFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { Card, Col, Row, Typography } from "antd";
import "./Query.css";

export default function Query() {
  return (
    <div className="query-page-main">
      <h1 className="query-title">WHY US</h1>
      <hr/>
      <div className="query-description">
      <Row>
        <Col lg={1} xs={2}></Col>
        <Col lg={22} xs={20}>
          <Row>
            <Col lg={11} xs={24}>
              <div>
                <Typography className="query-section-title">
                  Why You Should Buy From Kraafty
                </Typography>
                <Typography className="query-text">
                  If you're still reading this, we are pretty sure you already
                  recognize the benefit of using Ditto. However, if you are
                  still not convinced, here's a few extra points to change your
                  mind
                </Typography>
                <div className="listed-text">
                  <ul>
                    <li>Authentic Craftsmanship</li>
                    <li>Ethically Produced and prioritize fair trade</li>
                    <li>Use of Eco-Friendly Materials</li>
                    <li>Preserve traditional skills and celebrate India’s rich heritage</li>
                    <li>offering top-notch quality at competitive prices, with 100% customer satisfaction</li>
                    <li>By choosing Kraafty, you’re supporting sustainable, ethical sourcing and joining a community that values cultural preservation.</li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg={2} xs={1}></Col>
            <Col lg={9} xs={22}>
              <div style={{ display:"flex",flexDirection:"column",gap:25 }}>
                <div>
                  <Card>
                    <div className="rightside-card">
                      <div className="rightside-icon">
                        <SafetyCertificateFilled
                          style={{ color: "#5e5ee9", fontSize: 26 }}
                        />
                      </div>
                      <div>
                        <Typography.Title level={3} className="rightside-card-title">
                        Authentic Indian Craftsmanship
                        </Typography.Title>
                        <Typography className="card-description">
                        Every item is handcrafted by skilled artisans, blending centuries-old techniques with modern design.
                        </Typography>
                      </div>
                      <div className="rightside-secoend-icon">
                        <RightOutlined style={{ fontSize: 26 }} />
                      </div>
                    </div>
                  </Card>
                </div>
                <div>
                  <Card>
                    <div className="rightside-card">
                      <div className="rightside-icon">
                        {/* <CustomerServiceOutlined */}
                        <SafetyCertificateFilled
                          style={{ color: "#5e5ee9", fontSize: 26 }}
                        />
                      </div>
                      <div>
                        <Typography.Title level={3} className="rightside-card-title">
                        Eco-Friendly and Sustainable
                        </Typography.Title>
                        <Typography className="card-description">
                        Our products use natural, locally sourced materials like cotton, jute, and wood, supporting an eco-conscious lifestyle.
                        </Typography>
                      </div>
                      <div className="rightside-secoend-icon">
                        <RightOutlined style={{ fontSize: 26 }} />
                      </div>
                    </div>
                  </Card>
                </div>
                <div>
                  <Card>
                    <div className="rightside-card">
                      <div className="rightside-icon">
                        {/* <SearchOutlined */}
                        <SafetyCertificateFilled
                          style={{ color: "#5e5ee9", fontSize: 26 }}
                        />
                      </div>
                      <div>
                        <Typography.Title level={3} className="rightside-card-title">
                        Quality and Customer Satisfaction
                        </Typography.Title>
                        <Typography className="card-description">
                        We’re committed to delivering high-quality, unique products at competitive prices, ensuring 100% satisfaction for our customers.
                        </Typography>
                      </div>
                      <div className="rightside-secoend-icon">
                        <RightOutlined style={{ fontSize: 26 }} />
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col lg={1} xs={2}></Col>
      </Row>
      </div>
    </div>
  );
}
