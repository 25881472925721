import React from 'react'
import Hero from '../Components/Hero/Hero'
import Popular from '../Components/Popular/Popular'
// import Offers from '../Components/Offers/Offers'
import NewCollections from '../Components/NewCollections/NewCollections'
// import NewsLetter from '../Components/NewsLetter/NewsLetter'
import CustomerReviews from '../Components/CustomerReviews/CustomerReviews'
import AboutUs from '../Components/AboutUs/AboutUs'
import ContactUs from '../Components/ContactUs/ContactUs'
import Scroller from '../Components/Scroller/Scroller'
import Query from '../Components/Query/Query'

const Shop = () => {
  return (
    <div>
      {/* <Scroller/> */}
      <Hero/>
      <Popular/>
      <NewCollections/>
      <Query/>
      <AboutUs/>
      <CustomerReviews/>
      <ContactUs/>
    </div>
  )
};

export default Shop;
