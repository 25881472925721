import React, { useState } from "react";
import {
  faEnvelopeOpenText,
  faHdd,
  faPhoneAlt,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import './ContactUs.css';

const contactInfoList = [
  {
    icon: faEnvelopeOpenText,
    label: "kraafty@gmail.com",
    href: "mailto:kraafty@gmail.com",
  },
  {
    icon: faPhoneAlt,
    label: "+91 8005629558, +91 9468599356",
    href: "callto:+91 8005629558",
  },
  { icon: faHdd, label: "kraafty.com", href: "kraafty.com" },
  {
    icon: faLocationDot,
    label: "C-11, Raghunath Colony, Galta Gate, Jaipur, Rajasthan, 302003",
    href: "https://maps.google.com/?q=123+Kraafty+Street,City,Country",
  },
];

const ContactForm = () => {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    country_code: "",
    mobile_number: "",
    query: "",
  });

  const [successMessage, setSuccessMessage] = useState("");

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   } else {
  //     try {
  //       const response = await fetch(
  //         "https://kraafty-backend.onrender.com/api/create-query/",
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify(formData),
  //         }
  //       );
  //         console.log("responseData", response);
  //       if (response.ok) {
  //         const result = await response.json();

  //         console.log("Query submitted successfully:", result);
  //         // Reset form or show success message
  //         alert("Your query is submitted successfully, thank you!");
  //       } else {
  //         console.error("Failed to submit query.");
  //         // Show error message
  //       }
  //     } catch (error) {
  //       console.error("Error submitting query:", error);
  //       // Show error message
  //     }
  //   }

  //   setValidated(true);
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      try {
        const response = await fetch(
          "https://kraafty-backend.onrender.com/api/create-query/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        console.log("responseData", response);

        if (response.ok) {
          // If the response status is 200 and ok is true, show the success message
          console.log("Query submitted successfully.");

          // Display the success message
          setSuccessMessage("Your query has been submitted successfully.");

          // Reset form fields
          setFormData({
            name: "",
            email: "",
            country_code: "",
            mobile_number: "",
            query: "",
          });
        } else {
          console.error("Failed to submit query.");
          setSuccessMessage(""); // clear the success message if there's an error
        }
      } catch (error) {
        console.error("Error submitting query:", error);
        setSuccessMessage(""); // clear the success message if there's an error
      }
    }

    setValidated(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <form
      className=""
      noValidate
      validated={validated.toString()}
      onSubmit={handleSubmit}
    >
      <div className="mb-4">
        <input
          type="text"
          name="name"
          className="min-h-[48px] leading-[48px] bg-white dark:bg-[#2A384C] border border-transparent rounded-xl focus:outline-none focus:border focus:border-[#86b7fe] w-full px-2"
          placeholder="Enter Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>

      <div className="mb-4 flex">
        <input
          type="text"
          name="country_code"
          className="min-h-[48px] leading-[48px] bg-white dark:bg-[#2A384C] border border-transparent rounded-xl focus:outline-none focus:border focus:border-[#86b7fe] w-1/2 px-2 mr-2" // Changed to w-1/2
          placeholder="Country Code"
          value={formData.country_code}
          onChange={handleChange}
          required
        />
        <input
          type="tel"
          name="mobile_number"
          className="min-h-[48px] leading-[48px] bg-white dark:bg-[#2A384C] border border-transparent rounded-xl focus:outline-none focus:border focus:border-[#86b7fe] w-1/2 px-2" // Changed to w-1/2
          placeholder="Mobile Number"
          value={formData.mobile_number}
          onChange={handleChange}
          required
        />
      </div>

      <div className="mb-4">
        <input
          type="email"
          name="email"
          className="min-h-[48px] leading-[48px] bg-white dark:bg-[#2A384C] border border-transparent rounded-xl focus:outline-none focus:border focus:border-[#86b7fe] w-full px-2"
          placeholder="Enter Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>

      <div className="mb-4">
        <textarea
          name="query"
          className="min-h-[48px] leading-[48px] bg-white dark:bg-[#2A384C] border border-transparent rounded-xl focus:outline-none focus:border focus:border-[#86b7fe] w-full px-2"
          placeholder="Enter Message"
          rows="4"
          value={formData.query}
          onChange={handleChange}
          required
        ></textarea>
      </div>
      <div className="text-start">
        <button
          type="submit"
          className="bg-blue-600 hover:bg-opacity-90 text-white px-8 py-3 rounded mb-4"
        >
          Submit
        </button>
      </div>
      {successMessage && (
        <p className="text-green-600 mt-2">{successMessage}</p>
      )}
    </form>
  );
};

const ContactFormCard = () => (
  <div className="bg-gray-100 dark:bg-[#162231] shadow-xl rounded-2xl p-6 md:p-12">
    <h2 className="text-2xl md:text-[37px] leading-none font-bold mb-4 text-center">
      Contact Us
    </h2>
    <p className="text-lg mb-4">Have a question or need help? Let us know!</p>

    <ContactForm />
  </div>
);

const ContactInfo = ({ contactInfoList }) => (
  <div className="mt-5 pt-md-4">
    {contactInfoList.map((info, i) => (
      <div
        className="bg-gray-100 shadow dark:bg-gray-800 max-w-[350px] mt-6 flex items-center rounded-xl p-5"
        key={i}
      >
        <FontAwesomeIcon icon={info.icon} className="text-3xl px-2" />
        <a className="text-lg font-medium ml-4" href={info.href || "#!"}>
          {info.label}
        </a>
      </div>
    ))}
  </div>
);

ContactInfo.propTypes = {
  contactInfoList: PropTypes.array.isRequired,
};

const ContactUs = () => {
  return (
    <section id="contactus-form" className="ezy__contact9 light md:py-24 bg-white dark:bg-[#0b1727] text-zinc-900 dark:text-white overflow-hidden">
      <div className="container px-4">
        <div className="grid grid-cols-12 py-6 lg:gap-8">
          <div className="col-span-12 lg:col-span-5 mb-12 lg:mb-0 lg:ml-14">
            <h2 className="text-2xl leading-none md:text-[37px] font-bold mb-6 text-center">
              How can we help you?
            </h2>
            <p className="text-lg text-center">
              We're here to support you with any inquiries or assistance you
              need. Feel free to get in touch with us through your preferred
              method of communication.
            </p>

            <ContactInfo contactInfoList={contactInfoList} />
          </div>

          <div className="col-span-12 lg:col-span-5 lg:col-start-8 lg:mr-14">
            <ContactFormCard />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
