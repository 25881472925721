import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrum from '../Components/Breadcrums/Breadcrum';
import ProductDisplay from '../Components/ProductDisplay/ProductDisplay';
import DescriptionBox from '../Components/DescriptionBox/DescriptionBox';
import RelatedProducts from '../Components/RelatedProducts/RelatedProducts';
import ContactUs from '../Components/ContactUs/ContactUs';
import axios from 'axios';

const Product = () => {
  const { productId } = useParams(); // Get productId from route params
  const [productData, setProductData] = useState(null); // State for product data
  const [selectedSize, setSelectedSize] = useState('S'); // Default size

  // Function to handle size change and re-fetch data
  const handleSizeChange = (size) => {
    setSelectedSize(size); // Update selected size
  };

  // Fetch product data based on selected size and productId
  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await axios.get(`https://kraafty-backend.onrender.com/api/get-product-detail/?product_pk=${productId}&size=${selectedSize}`);
        setProductData(response.data);
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };

    fetchProductData(); // Fetch product data on component mount or size change
  }, [productId, selectedSize]); // Re-fetch data when productId or selectedSize changes

  // Display loading message while fetching product data
  if (!productData) {
    return <div>Loading...</div>;
  }

  // Filter related products by product_code
  const { product_code } = productData.data;
  const related_products = productData.related_product.filter(
    (product) => product.product_code === product_code
  );

  return (
    <div>
      <Breadcrum product={productData.data} />
      <ProductDisplay
        product={productData.data}
        selectedSize={selectedSize}
        onSizeChange={handleSizeChange} // Pass size change handler
      />
      <RelatedProducts relatedProducts={related_products} />
      <DescriptionBox product={productData.data} />
      <ContactUs />
    </div>
  );
};

export default Product;



// import React, { useEffect, useState } from 'react';
// // import { ShopContext } from '../Context/ShopContext';
// import { useParams } from 'react-router-dom';
// import Breadcrum from '../Components/Breadcrums/Breadcrum';
// import ProductDisplay from '../Components/ProductDisplay/ProductDisplay';
// import DescriptionBox from '../Components/DescriptionBox/DescriptionBox';
// import RelatedProducts from '../Components/RelatedProducts/RelatedProducts';
// import ContactUs from '../Components/ContactUs/ContactUs'
// import axios from 'axios';

// const Product = () => {
//   const { productId } = useParams();
//   const [productData, setProductData] = useState(null);

//   useEffect(() => {
//     const fetchProductData = async () => {
//       try {
//         const response = await axios.get(`https://kraafty-backend.onrender.com/api/get-product-detail/?product_pk=${productId}`);
//         console.log("hello",response.data.data)
//         setProductData(response.data);

//       } catch (error) {
//         console.error('Error fetching product data:', error);
//       }
//     };
//     fetchProductData();
//   }, [productId]);

//   if (!productData) {
//     return <div>Loading...</div>;
//   }

//   // Filter related products based on the product_code
//   const { product_code } = productData.data;
//   const related_products = productData.related_product.filter(
//     (product) => product.product_code === product_code
//   );

//   return (
//     <div>
//       <Breadcrum product={productData.data} />
//       <ProductDisplay product={productData.data} />
//       <DescriptionBox description={productData.data.description} />
//       <RelatedProducts relatedProducts={related_products} />

//       <ContactUs/>
//     </div>
//   );
// }

// export default Product;




