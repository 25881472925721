import React, { useState, useEffect } from "react";
import "./Scroller.css";

const Scroller = () => {
  const messages = [
    "Welcome to Our Store!",
    // <a href="https://www.amazon.com" target="blank">
    //   Go to Amazon
    // </a>,
    "Limited Time Offer: 20% Off Selected Items!",
  ];

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) =>
        prevIndex === messages.length - 1 ? 0 : prevIndex + 1
      );
    }, 2000);

    return () => clearInterval(interval);
  }, [messages.length]);

  return (
    <div className="header-scroller">
      <p>{messages[currentMessageIndex]}</p>
    </div>
  );
};

export default Scroller;
